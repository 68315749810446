import { useMemo } from 'react'
import { meshifyColor } from '../../../../../../utils/helperFunctions.ts/meshGradients'
import { usePrimaryCommunity } from '../../../../../../utils/hooks/community/usePrimaryCommunity'
import { useUserColors } from '../../../../../../utils/hooks/customization/colors'
import { useMasterSchedule } from '../../../../../../utils/hooks/schedule/useMasterSchedule'
import { useCurrentUser } from '../../../../../../utils/hooks/self/useSelfInfo'
import { useUser } from '../../../../../../utils/hooks/users/useUser'
import { CustomClassScheduleType, ScheduleClassEvent } from '../../../../../../utils/types/scheduleTypes'
import { GenericBlockTemplate } from './GenericBlockTemplate'

export const CustomClassBlock = (props: { classBlock: CustomClassScheduleType; sb: ScheduleClassEvent }) => {
  const { classBlock, sb } = props
  const community = usePrimaryCommunity()
  const schedule = useMasterSchedule(community?.community)
  const user = useCurrentUser(true)
  const src = useMemo(() => {
      if (user?.theme === 2)
        return 'https://cdn.discordapp.com/attachments/957350482639212545/1062216659575767130/CroppedKimiko.png'

      return 'logo.png'
  }, [user?.theme])
  const color = useUserColors()
  return (
    <GenericBlockTemplate
      name={classBlock.name}
      caption={schedule?.periods[classBlock.periodID]}
      image={
        <div className={`w-full h-full brightness-90 flex flex-row items-center justify-center relative`}>
          <div
            className={`absolute top-0 left-0 w-full h-full blur-lg`}
            style={{
              ...meshifyColor(color),
            }}
          />
          <img src={src} className={`w-12 h-12 grayscale-[0.5]`} />
        </div>
      }
      start={sb.from}
      end={sb.to}
    />
  )
}
