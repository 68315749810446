import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import { ScheduleClass } from '../../../../utils/classes/ScheduleClass'
import { useCommunityCourses } from '../../../../utils/hooks/community/useCommunityCourses'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { useUserColors } from '../../../../utils/hooks/customization/colors'
import { useMasterSchedule } from '../../../../utils/hooks/schedule/useMasterSchedule'
import { useUserSchedule } from '../../../../utils/hooks/schedule/useUserSchedulePrefs'
import { ClassScheduleType, CustomClassScheduleType, ScheduleClassEvent } from '../../../../utils/types/scheduleTypes'
import useForceUpdate from '../../../../utils/useForceUpdate'
const epochify = (x: number) => {
  const d = new Date()
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d.getTime() + x
  // changes ms past midnight to ms past epoch
}
const formatTime = (x: number) =>
  ~~Math.abs((epochify(x) - Date.now()) / 1000) <= 100
    ? ~~((epochify(x) - Date.now()) / 1000)
    : ~~Math.abs((epochify(x) - Date.now()) / 1000 / 60) <= 130
    ? ~~((epochify(x) - Date.now()) / 1000 / 60)
    : ~~Math.abs((epochify(x) - Date.now()) / 1000 / 60 / 60)
const timeUnits = (x: number) => {
  const secs = x / 1000
  if (secs < 100) {
    return `seconds`
  } else if (secs < 60 * 130) {
    return `minutes `
  } else {
    return `hours`
  }
}

export const CurrentClassSchedule = () => {
  const community = usePrimaryCommunity()
  const courses = useCommunityCourses(community?.community)
  const masterSchedule = useMasterSchedule(community?.community)
  const schedulePrefs = useUserSchedule(community?.community)
  const [scheduleDay, setScheduleDay] = useState(null as ScheduleClassEvent[] | null)
  const [tick, setTick] = useState(0)
  const color = useUserColors()
  useEffect(() => {
    const update = () => {
      ScheduleClass.getInstance()
        .parseScheduleDay(masterSchedule ?? undefined)
        .then(day => {
          setScheduleDay(day ?? null)
        })
    }
    update()
    const interval = setInterval(() => {
      update()
    }, 1000)
    return () => clearInterval(interval)
    //TODO - update scheduleDay when midnight is reached
  }, [masterSchedule, schedulePrefs])
  useEffect(() => {
    const refresh = setInterval(() => {
      setTick(t => (t + 1) % 10)
    }, 1000)
    return () => {
      clearInterval(refresh)
    }
  }, [])
  const currentClass = useMemo(() => {
    if (!scheduleDay) {
      return null
    }
    const blacklistedPeriods = new Set()
    schedulePrefs?.classes.map(classPref => classPref.type === 'prep' && blacklistedPeriods.add(classPref.periodID))
    const cleanedScheduleDay = scheduleDay.filter(x => x.period && !blacklistedPeriods.has(x.period))

    const currentPeriod = cleanedScheduleDay.find(x => epochify(x.from) <= Date.now() && epochify(x.to) >= Date.now())
    if (!currentPeriod) {
      const nextPeriod = cleanedScheduleDay
        .filter(x => epochify(x.from) >= Date.now())
        .sort((a, b) => a.from - b.from)[0]
      if (!nextPeriod) {
        const prevPeriod = cleanedScheduleDay.filter(x => epochify(x.to) <= Date.now()).sort((a, b) => b.to - a.to)[0]
        return {
          period: prevPeriod,
          type: 'prev',
        }
      }
      return { period: nextPeriod, type: 'next' }
    }
    return { period: currentPeriod, type: 'current' }
  }, [scheduleDay, masterSchedule, schedulePrefs, tick])

  const scheduleClassOverride = schedulePrefs?.classes?.find(x => x?.periodID === currentClass?.period?.period) || {
    type: 'custom',
    name: masterSchedule?.periods?.[currentClass?.period?.period || ''],
  }
  if (!currentClass?.period?.period) return null
  // if (!scheduleClassOverride) return null
  const userClass = (() => {
    switch (scheduleClassOverride?.type) {
      case 'class':
        return {
          name: courses?.get((scheduleClassOverride as ClassScheduleType).class)?.name,
          link: `/app/courses/${courses?.get((scheduleClassOverride as ClassScheduleType).class)?.id}`,
        }
      case 'custom':
        return { name: (scheduleClassOverride as CustomClassScheduleType)?.name, link: null }
      default:
        return { name: `${scheduleClassOverride?.type}`, link: null }
    }
  })()
  const completion =
    (epochify(currentClass?.period.to!) - Date.now()) / (currentClass?.period.to! - currentClass?.period.from!)
  return (
    <div className={`w-full flex flex-col p-6 pb-6 gap-8`}>
      <div className={`flex flex-row items-center justify-end gap-4 dark:text-gray-200/70 text-gray-100/70`}>
        <div className={`flex flex-col items-end gap-4`}>
          <Link href={userClass.link || ''}>
            <h2
              className={`text-3xl font-bold text-white text-shadow-sm -0 underline-offset-2 hover:text-${color} transition-all cursor-pointer font-montserrat font-black text-right`}
            >
              {userClass.name}
            </h2>
          </Link>
          <span className="">
            {currentClass?.type === 'current' && `ends in`}
            {currentClass?.type === 'next' && `starts in`}
            {currentClass?.type === 'prev' && `ended`}
          </span>
        </div>
        <div className={`  flex flex-row justify-center`}>
          <div className={`relative w-32 h-32`}>
            <svg className="absolute top-0 left-0 z-20 w-full h-full -rotate-90 rounded-full" viewBox="0 0 240 240">
              <circle
                className="rounded-full stroke-gray-800/70 "
                stroke="white"
                strokeWidth="22"
                fill="transparent"
                r="104"
                cx="120"
                cy="120"
              />

              <circle
                className={`rounded-full stroke-${color} transition-all`}
                stroke="white"
                strokeWidth="22"
                strokeDasharray={`${Math.PI * 208} ${Math.PI * 208}`}
                strokeDashoffset={`${Math.PI * 208 * completion}`}
                strokeLinecap="round"
                fill="transparent"
                r="104"
                cx="120"
                cy="120"
              />
            </svg>
            <div
              className={`absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-[95%] h-[95%] backdrop-blur-md rounded-full`}
            />
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 flex flex-col items-center`}
            >
              <span className={`text-3xl font-bold text-shadow-sm text-${color}`}>
                {/* {offset} */}
                {currentClass?.type === 'next'
                  ? formatTime(currentClass.period.from!)
                  : formatTime(currentClass?.period.to!)}
              </span>
              <span className={`text-base font-medium text-shadow-sm text-gray-100`}>
                {
                  //TODO: fix start and end times borking
                }
                {currentClass?.type === 'next' &&
                  timeUnits(Math.abs(epochify(currentClass?.period.from!) - Date.now()))}
                {currentClass?.type === 'current' &&
                  timeUnits(Math.abs(epochify(currentClass?.period.to!) - Date.now()))}
                {currentClass?.type === 'prev' && timeUnits(Math.abs(epochify(currentClass?.period.to!) - Date.now()))}
              </span>
              {epochify(currentClass?.period.to!) - Date.now() < 0 && (
                <span className={`text-sm font-medium text-shadow-sm text-gray-100`}>ago</span>
              )}

              {/* {epochify(currentClass?.period.to)} */}
            </div>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(currentClass)} */}
    </div>
  )
}
