import { useMemo } from 'react'
import { usePrimaryCommunity } from '../../../../../../utils/hooks/community/usePrimaryCommunity'
import { useMasterSchedule } from '../../../../../../utils/hooks/schedule/useMasterSchedule'
import { useUserSchedule } from '../../../../../../utils/hooks/schedule/useUserSchedulePrefs'
import {
  ClassScheduleType,
  CustomClassScheduleType,
  PrepClassScheduleType,
  ScheduleClass,
  ScheduleClassEvent,
} from '../../../../../../utils/types/scheduleTypes'
import { ClassClassBlock } from './ClassClassBlock'
import { CustomClassBlock } from './CustomClassBlock'
import { PrepClassBlock } from './PrepBlock'

export const ClassBlock = (props: { classBlock: ScheduleClassEvent }) => {
  const { classBlock } = props
  const community = usePrimaryCommunity()
  const schedulePrefs = useUserSchedule(community?.community)
  const masterSched = useMasterSchedule(community?.community)
  const scheduleMap = useMemo(() => {
    const map = new Map<string, ScheduleClass>()
    schedulePrefs?.classes.map(x => {
      map.set(x.periodID, x)
    })
    return map
  }, [schedulePrefs])
  const currentPeriod = scheduleMap.get(classBlock.period)
  if (!currentPeriod) {
    return (
      <CustomClassBlock
        classBlock={
          {
            name: masterSched?.periods[classBlock.period!],
            type: 'custom',
          } as CustomClassScheduleType
        }
        sb={classBlock}
      />
    )
  }
  switch (currentPeriod?.type) {
    case 'prep':
      return <PrepClassBlock classBlock={currentPeriod as PrepClassScheduleType} sb={classBlock} />
    case 'class':
      return <ClassClassBlock classBlock={currentPeriod as ClassScheduleType} sb={classBlock} />
    case 'custom':
      return <CustomClassBlock classBlock={currentPeriod as CustomClassScheduleType} sb={classBlock} />
    default:
      return (
        <div>
          Unknown block type {currentPeriod?.type} e {JSON.stringify(currentPeriod)}
        </div>
      )
  }
}
export default ClassBlock
