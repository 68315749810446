import { useState, useEffect, useRef } from 'react'
import { CourseMaterialsDataClass } from '../../classes/CourseDataClasses/CourseAssignmentDataClass'
import { fetcher } from '../../classes/Fetcher'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Announcement, Course, UpcomingCourseEvent } from '../../types/courseTypes'
import { useCommunityCourses } from '../community/useCommunityCourses'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCourses } from './useUserCourses'

export const useAllCourseUpcoming = (communityID?: string, cachable: boolean = true, ignore: boolean = false) => {
  const [courseUpcoming, setCourseUpcoming] = useState(undefined as null | UpcomingCourseEvent[] | undefined)
  const courses = useCommunityCourses(communityID)
  const comm = usePrimaryCommunity()
  const cIDs = useRef<string>()
  useEffect(() => {
    if (!courses || !comm?.courses) return
    if (courses.size !== Object.keys(comm.courses).length) return
    const generatedcIDs = JSON.stringify(Array.from(courses.keys()).sort((a, b) => a.localeCompare(b)))
    if (cIDs.current === generatedcIDs) return
    cIDs.current = generatedcIDs
    const courseUpcomingMap = new Map<string, Set<UpcomingCourseEvent>>()
    let cancels = [] as (() => void)[]
    const updateUpcoming = () => {
      // merge all course upcomings into one array
      const upcomings = [] as UpcomingCourseEvent[]
      courses.forEach(course => {
        upcomings.push(...(courseUpcomingMap.get(course.id) ?? []))
      })
      upcomings.sort((a, b) => a.start - b.start)
      const oldThreshold = Date.now() - 1000 * 60 * 60 * 24 * 14 // 2 weeks ago
      const notOld = upcomings.findIndex(a => a.start >= Date.now())
      setCourseUpcoming(upcomings.slice(notOld, notOld+20))
    }

    courses.forEach(course => {
      const courseUpcoming =
        CourseMaterialsDataClass.getInstance(course.id).upcomingEvents &&
        (Array.from(CourseMaterialsDataClass.getInstance(course.id).upcomingEvents?.values() || []).map(e => ({
          ...e,
          course: course,
        })) as UpcomingCourseEvent[])
      if (courseUpcoming) {
        courseUpcomingMap.set(course.id, new Set(courseUpcoming))
      }
      const setUpcomings = (event: any) => {
        // console.warn("Upcoming update",)
        const courseUpcoming =
          CourseMaterialsDataClass.getInstance(course.id).upcomingEvents &&
          (Array.from(CourseMaterialsDataClass.getInstance(course.id).upcomingEvents?.values() || []).map(e => ({
            ...e,
            course: course,
          })) as UpcomingCourseEvent[])
        if (courseUpcoming) {
          courseUpcomingMap.set(course.id, new Set(courseUpcoming))
        }
        updateUpcoming()
      }
      // CourseMaterialsDataClass.getInstance(course.id).on('upcomingEventsUpdate', (e)=>console.warn("Upcoming update",e))
      CourseMaterialsDataClass.getInstance(course.id).on('upcomingEventsUpdate', setUpcomings.bind(this))
      cancels.push(() => {
        CourseMaterialsDataClass.getInstance(course.id).off('upcomingEventsUpdate', setUpcomings)
      })
      !CourseMaterialsDataClass.getInstance(course.id).fetchingUpcoming &&
        CourseMaterialsDataClass.getInstance(course.id).getUpcomingEvents()
    })
    updateUpcoming()
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [comm, courses, cachable, ignore])
  // console.log("courseUpcoming",courseUpcoming)
  return courseUpcoming
}
