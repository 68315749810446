import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(utc)
dayjs.extend(relativeTime)

import { useRouter } from 'next/router'
import RemoveMarkdown from 'remove-markdown'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { Announcement, CourseAnnouncement } from '../../../../utils/types/courseTypes'
import MarkdownRenderer from '../../../Utils/MarkdownRenderer'
import { useState } from 'react'
import { AnnouncementModal } from './AnnouncementModal'
import { useUserColors } from '../../../../utils/hooks/customization/colors'

export const DashboardAnnouncementViewer = (props: { announcement: CourseAnnouncement }) => {
  const { announcement } = props
  const community = usePrimaryCommunity()
  const [openPopup, setOpenPopup] = useState(false)
  const router = useRouter()
  const course = router.query.course as string
  const parsed = RemoveMarkdown(announcement.body ?? '', {
    stripListLeaders: true,
    gfm: false,
  })
  const color = useUserColors()
  return (
    <>
      <div
        className={`flex flex-col gap-2 p-4 dark:bg-gray-800/80 backdrop-blur-md bg-gray-100/70 shadow-sm rounded-xl w-full transition-all ease-out duration-150 cursor-pointer flex-shrink-0 group`}
        onClick={e => {
          // setShowmore(!showmore)
          setOpenPopup(true)
          e.stopPropagation()
        }}
        key={`${announcement.id}-${announcement.body}`}
      >
        <div className={`flex flex-row gap-4 justify-between`}>
          <div className={`flex flex-row gap-2 items-center`}>
            <img className={`w-8 h-8 rounded-lg object-cover`} src={announcement.user.picture} />
            <span>
              <span className={`text-sm dark:text-gray-500`}>{announcement.user.name}</span>
            </span>
          </div>
          <div>
            <span className={`text-xs dark:text-gray-500`}>{dayjs(announcement.created).fromNow()}</span>
          </div>
        </div>
        <div className={`flex flex-col gap-2 flex-grow`}>
          {announcement.title && <span>{announcement.title}</span>}
          <div className={`relative h-36 2xl:h-[4.5rem] w-full line-clamp-6 leading-6`}>{parsed}</div>
          <div className={`flex flex-row justify-between items-center`}>
            <span
              className={`text-gray-400 group-hover:text-${color} cursor-pointer transition-all duration-150 `}
            >
              Preview
            </span>
            <span className={`text-xs dark:text-gray-500 text-gray-700/40`}>
              {dayjs(announcement.created).format('MMMM DD YYYY')} at {dayjs(announcement.created).format('hh:mm A')}
            </span>
          </div>
        </div>
      </div>
      <AnnouncementModal announcement={announcement} open={openPopup} onClose={() => setOpenPopup(false)} />
    </>
  )
}
