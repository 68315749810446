import { useEffect, useState } from 'react'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useCurrentUser } from '../../../utils/hooks/self/useSelfInfo'

export const DashboardBackground = () => {
  const user = useCurrentUser(true)
  const [hue, setHue] = useState(0)
  const [bg, setBg] = useState('')
  useEffect(() => {
    if (user) {
      switch (user.theme) {
        case 0:
          setBg(`/defaults/background/light.png`)
        case 1:
          setBg(`/defaults/background/dark.png`)
          break
        case 2:
          setBg(`/kimikoBG.png`)
          break;
        default:
          setBg(`/defaults/background/dark.png`)
          break
      }
      
    }
  }, [user])
  const community = usePrimaryCommunity()
  // alert(defaultBG)
  return (
    <div
      className={`w-full h-full bg-cover bg-center absolute top-0 left-0`}
      style={{
        backgroundImage: `url(${bg})`,
        filter: `hue-rotate(${hue}deg)`,
      }}
    />
  )
}
