import localforage from 'localforage'
import { useState, useEffect } from 'react'
import { CourseAnnouncementsDataClass } from '../../classes/CourseDataClasses/CourseAnnouncementsDataClass'
import { fetcher } from '../../classes/Fetcher'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Announcement, Course, CourseAnnouncement } from '../../types/courseTypes'
import { useCommunityCourses } from '../community/useCommunityCourses'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCourses } from './useUserCourses'

export const useAllSchoolAnnouncements = (communityID?: string, cachable: boolean = true) => {
  const [schoolAnnouncements, setSchoolAnnouncements] = useState(null as null | CourseAnnouncement[])
  const courses = useCommunityCourses(communityID)
  const user = useCurrentUser()
  useEffect(() => {
    if (!courses || !courses.size) return
    console.log('useAllSchoolAnnouncementsDeps:', courses, user, cachable)
    const schoolAnnouncementsMap = new Map<string, Set<Announcement>>()
    let schoolwideAnnouncements = new Map<string, Announcement>()
    let cancels = [] as (() => void)[]
    let clog = 0
    let lastUpdate = 0
    let updateQueued = false
    const updateAnnouncements = () => {
      if (lastUpdate > Date.now() - 500) {
        if (updateQueued) return
        updateQueued = true
        setTimeout(() => {
          updateQueued = false
          updateAnnouncements()
        }, 500)
        return
      }
      lastUpdate = Date.now()

      // merge all course announcements into one array
      const announcements = [] as CourseAnnouncement[]
      courses.forEach(course => {
        announcements.push(
          ...Array.from(schoolAnnouncementsMap.get(course.id) ?? []).map(a => ({
            ...a,
            courseID: course.id,
            course: course,
          }))
        )
      })
      announcements.push(...Array.from(schoolwideAnnouncements?.values() || []))
      announcements.sort((a, b) => b.created - a.created)
      const oldThreshold = Date.now() - 1000 * 60 * 60 * 24 * 14
      // const notOld = announcements.findIndex(a => a.created >= oldThreshold)
      setSchoolAnnouncements(announcements.slice(0, 50))
      console.log('update', clog++, announcements.length)
      // console.log('announcements', announcements.slice(0, Math.min(~notOld ? notOld: 20, 20)), notOld,20)
    }
    localforage.getItem(`community.${user?.primaryCommunity}.schoolAnnouncements`).then(data => {
      console.log('schoolAnnouncements', data)
      const map = data as Map<string, Announcement>
      Array.from(map?.values() || []).forEach(announcement => {
        schoolwideAnnouncements.set(announcement.id, announcement)
      })
      updateAnnouncements()
    })
    courses.forEach(course => {
      const schoolAnnouncements = CourseAnnouncementsDataClass.getInstance(course.id).announcements
      schoolAnnouncementsMap.set(course.id, new Set(schoolAnnouncements))
      const setAnnouncements = (event: any) => {
        const announcementSet = schoolAnnouncementsMap.get(course.id)
        if (announcementSet) {
          CourseAnnouncementsDataClass.getInstance(course.id).announcements.forEach(announcement => {
            announcementSet.add(announcement)
          })
        }
        // schoolAnnouncementsMap.set(course.id, new Set(event.announcements))
        updateAnnouncements()
      }
      CourseAnnouncementsDataClass.getInstance(course.id).on('announcementsUpdate', setAnnouncements)
      cancels.push(() => {
        CourseAnnouncementsDataClass.getInstance(course.id).off('announcementsUpdate', setAnnouncements)
      })
    })
    updateAnnouncements()

    fetcher(`${API_DOMAIN}/community/${user?.primaryCommunity}/LMS/announcements?fresh=1`, undefined, 'important')
      .then(res => (res.ok ? res.json() : null))
      .then(
        res =>
          res &&
          (res as { announcements: Announcement[] }).announcements.forEach(a => {
            schoolwideAnnouncements.set(a.id, {
              ...a,
              school: true,
            })
          })
      )
      .then(x => {
        //   console.log('schoolwide announcements', schoolwideAnnouncements))
        localforage.setItem(`community.${user?.primaryCommunity}.schoolAnnouncements`, schoolwideAnnouncements)
        updateAnnouncements()
      })

    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [JSON.stringify(Array.from(courses?.values() || [])), cachable, JSON.stringify(user)])
  return schoolAnnouncements
}
