import EventEmitter from 'events'
import { API_DOMAIN } from '../constants'
import {
  ABABSchedule,
  DailySchedule,
  GenericMasterSchedule,
  GenericUserSchedule,
  ScheduleType,
  WeeklySchedule,
} from '../types/scheduleTypes'
import { fetcher } from './Fetcher'
import { UserCommunities } from './UserCommunities'

export class ScheduleClass extends EventEmitter {
  private static instance: ScheduleClass
  masterSchedules!: Map<string, GenericMasterSchedule | null>
  userSchedules!: Map<string, GenericUserSchedule | null>
  private constructor() {
    super()
    this.loadSchedules()
    this.fetchAllSchedules()
    this.linkSelf()
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new ScheduleClass()
    }
    return this.instance
  }
  async linkSelf() {
    const communities = UserCommunities.getInstance()
    const update = () => {
      this.fetchAllSchedules()
    }

    communities.addListener('communitiesUpdate', update.bind(this))
  }
  loadSchedules() {
    this.masterSchedules = new Map()
    this.userSchedules = new Map()
    const cachedMasterSchedules = localStorage.getItem('masterSchedules')
    if (cachedMasterSchedules) {
      const parsedSchedules = JSON.parse(cachedMasterSchedules)
      for (const [key, value] of Object.entries(parsedSchedules)) {
        this.masterSchedules.set(key, value as GenericMasterSchedule)
      }
    }
    const cachedUserSchedules = localStorage.getItem('userSchedules')
    if (cachedUserSchedules) {
      const parsedSchedules = JSON.parse(cachedUserSchedules)
      for (const [key, value] of Object.entries(parsedSchedules)) {
        this.userSchedules.set(key, value as GenericUserSchedule)
      }
    }
  }
  saveSchedules() {
    //convert maps to objects
    const masterSchedules = {} as { [key: string]: GenericMasterSchedule }
    for (const [key, value] of this.masterSchedules.entries()) {
      if (!value) continue
      masterSchedules[key] = value
    }
    const userSchedules = {} as { [key: string]: GenericUserSchedule }
    for (const [key, value] of this.userSchedules.entries()) {
      if (!value) continue
      userSchedules[key] = value
    }
    localStorage.setItem('masterSchedules', JSON.stringify(masterSchedules))
    localStorage.setItem('userSchedules', JSON.stringify(userSchedules))
  }
  async fetchMasterSchedule(community: string) {
    const url = `${API_DOMAIN}/community/${community}/schedule`
    const response = await fetch(url)
    if (!response.ok) {
      this.masterSchedules.set(community, null)
      this.saveSchedules()
      this.emit('masterScheduleUpdate', null)
      return null
    }

    const data = (await response.json()) as GenericMasterSchedule
    if (response.status === 200) {
      this.masterSchedules.set(community, data)
      this.saveSchedules()
      this.emit('masterScheduleUpdate', data)
    }

    return data
  }
  async fetchUserSchedule(community: string) {
    const url = `${API_DOMAIN}/community/${community}/schedule/@self`
    const response = await fetcher(url, undefined, 'important')
    if (!response.ok) {
      this.userSchedules.set(community, null)
      this.saveSchedules()
      this.emit('userScheduleUpdate', null)
      return null
    }
    const data = (await response.json()) as GenericUserSchedule
    if (response.status === 200) {
      this.userSchedules.set(community, data)
      this.saveSchedules()
      this.emit('userScheduleUpdate', data)
    }
    return data
  }
  async updateUserSchedule(community: string, schedule: Partial<GenericUserSchedule>) {
    const url = `${API_DOMAIN}/community/${community}/schedule/@self`
    const response = await fetcher(
      url,
      {
        method: 'POST',
        body: JSON.stringify(schedule),
      },
      'important'
    )
    if (!response.ok) return false
    await this.fetchUserSchedule(community)
    return true
  }
  async fetchSchedulePair(community: string) {
    const masterSchedule = await this.fetchMasterSchedule(community)
    const userSchedule = await this.fetchUserSchedule(community)
    return { masterSchedule, userSchedule }
  }
  async fetchAllSchedules() {
    const communities = UserCommunities.getInstance()
    const communitiesList = communities.communities
    const promises = []
    for (const community of communitiesList.values()) {
      promises.push(this.fetchSchedulePair(community.community))
    }
    const results = await Promise.all(promises)
    this.emit('schedulesFetched', results)
    return results
  }
  async parseScheduleDay(schedule?: GenericMasterSchedule) {
    if (!schedule) return null
    const { scheduleType } = schedule.schedule
    const currentScheduleDay = () => {
      if (scheduleType === ScheduleType.daily) {
        return (schedule.schedule as DailySchedule).classes
      } else if (scheduleType === ScheduleType.weekly) {
        const sched = schedule.schedule as WeeklySchedule
        const today = new Date()
        const day = today.getDay()
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        const currentday = days[day] as
          | 'sunday'
          | 'monday'
          | 'tuesday'
          | 'wednesday'
          | 'thursday'
          | 'friday'
          | 'saturday'
        return sched[currentday]
      } else if (scheduleType === ScheduleType.abab) {
        const today = new Date()
        const day = today.getDay()
        const sched = schedule.schedule as ABABSchedule
        return sched.a
      }
    }
    const classes = currentScheduleDay()
    return classes
  }
}
