import { ClockIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { usePrimaryCommunity } from '../../../../../../utils/hooks/community/usePrimaryCommunity'
import { useUserColors } from '../../../../../../utils/hooks/customization/colors'
import useForceUpdate from '../../../../../../utils/useForceUpdate'
const convertTime = (msFromMidnight: number) => {
  const hours = Math.floor(msFromMidnight / 3600000)
  const minutes = Math.floor((msFromMidnight % 3600000) / 60000)
  const seconds = Math.floor((msFromMidnight % 60000) / 1000)
  const time = `${String(hours > 12 ? hours - 12 : hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${
    hours >= 12 ? 'PM' : 'AM'
  }`
  return time
}
const epochify = (x: number) => {
  const d = new Date()
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d.getTime() + x
  // changes ms past midnight to ms past epoch
}
const convertMsToTime = (ms: number) => {
  const hours = Math.floor(ms / 3600000)
  const minutes = Math.floor((ms % 3600000) / 60000)
  const seconds = Math.floor((ms % 60000) / 1000)
  // less than 2 hours return minutes
  if (ms / 3600000 < 2) {
    return `${minutes + 60 * hours} mins`
  }
  // less than 2 minutes return seconds
  if (ms / 60000 < 2) {
    return `${seconds} secs`
  }
  // more than 2 hours return hours
  return `${hours} hrs`
}
export const GenericBlockTemplate = (props: {
  name?: string
  caption?: string
  image?: string | JSX.Element
  start?: number
  end?: number
}) => {
  const { name, caption, image, start, end } = props
  const update = useForceUpdate()
  useEffect(() => {
    const startTime =
      epochify(start!) > Date.now() &&
      setTimeout(() => {
        update()
      }, epochify(start!) - Date.now() + 250)
    const endTime =
      epochify(end!) > Date.now() &&
      setTimeout(() => {
        update()
      }, epochify(end!) - Date.now() + 250)
    return () => {
      startTime && clearTimeout(startTime)
      endTime && clearTimeout(endTime)
    }
  }, [end, start])
  const community = usePrimaryCommunity()
  const color = useUserColors()
  return (
    <div className={`w-full px-4`}>
      <div
        className={`w-full h-[7.5rem] flex-shrink-0 dark:bg-gray-800 bg-gray-100 dark:bg-opacity-70 bg-opacity-70 backdrop-blur-md p-4 rounded-lg shadow-md relative ${
          Date.now() >= epochify(end!) &&
          `bg-opacity-30 dark:brightness-75 dark:hover:brightness-100 hover:bg-opacity-50`
        } transition-all cursor-pointer hover:brightness-110`}
        onClick={() => {}}
      >
        <div
          className={`bg-${
            color
          } border-opacity-50 w-full h-full absolute z-0 top-0 left-0 rounded-lg bg-opacity-30 transition-all ${
            Date.now() >= epochify(start!) && Date.now() <= epochify(end!) ? `opacity-30` : `opacity-0`
          }`}
        />

        <div className={`absolute top-0 left-0 w-full h-full p-4 flex flex-col gap-2 items-start z-10`}>
          <div className={`flex flex-row gap-4 items-center w-full`}>
            <div className={`w-14 h-full flex-shrink-0 flex flex-col gap-2 items-center`}>
              <div className={`w-14 h-14 flex items-center justify-center flex-shrink-0 rounded-lg overflow-auto`}>
                {typeof image === 'string' ? (
                  <img src={image} className={`w-full h-full object-center object-cover`} />
                ) : (
                  image
                )}
              </div>
            </div>
            <div className={`flex-grow h-full flex flex-col relative`}>
              <div className={`absolute top-0 left-0 w-full h-full flex flex-col `}>
                <span
                  className={`font-bold text-2xl font-montserrat ${
                    Date.now() >= epochify(start!) && Date.now() <= epochify(end!)
                      ? `text-${color}`
                      : `text-gray-800/80 dark:text-gray-250`
                  } truncate`}
                >
                  {name}
                </span>
                <span className={`text-base font-wsans dark:text-gray-300`}>{caption}</span>
              </div>
            </div>
          </div>
          <div className={`flex flex-row items-center gap-4`}>
            <span className={`dark:text-gray-100/40 w-16`}>{convertMsToTime(end! - start!)}</span>
            <span>
              {!!start && !!end && (
                <div className={`flex flex-row items-center gap-2 dark:text-gray-100/60`}>
                  <ClockIcon className={`w-4 h-4`} />
                  <span className={`text-base font-wsans`}>
                    {convertTime(start)} – {convertTime(end)}
                  </span>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
